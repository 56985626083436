import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllBooks } from '../actions'
import { Alert } from 'antd'

function Recursos(props) {
  const { getAllBooks, books } = props
  const [filter, setFilter] = useState(0)

  useEffect(() => {
    getAllBooks()
  }, [])


  
  function close() {
    localStorage.removeItem('deletedResource')
  }


  const actions = [
    {
      label: 'Nuevo recurso',
      to: '/recursos/nuevo',
      type: 'link'
    }
  ]

  return (
    <>
      <Filters options={['Todos', 'Libros', 'Cursos', 'Artículos']} setFilter={setFilter} actions={actions} />
      <div className="container">


      {localStorage.getItem('deletedResource')? <div className="my-4">
        <Alert
          showIcon 
          message="Recurso eliminado correctamente."
          type="success"
          onClose={close}
          closable
        />
      </div> : ''}


      <DocumentList 
          data={books}
          filter={filter}
          fields={[
            {
              label: 'Título',
              name: 'titulo'
            },
            {
              label: 'Tipo',
              name: 'es_libro'
            },
            {
              label: 'Idioma',
              name: 'language'
            },
            {
              label: 'Creado el',
              name: 'createdAt'
            },
          ]} />
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllBooks: () => dispatch(getAllBooks()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recursos)