import { useEffect, useState } from 'react'


function UserNotas(props){

  const { id, notas } = props
  const numberOf = notas?.filter(notas => notas.userId === id && !notas.isRemoved).length ?? null


  return (
    <div>

        <div className="container py-5">
            <h3 className="text-xl font-semibold mb-3">
            Notas <span>{numberOf ? `(${numberOf})` : ``}</span>
            </h3>

            {notas?.filter(nota => nota.userId === id && !nota.isRemoved)?.map(nota => ( <>
                <div key={nota.id} className="mb-2 bg-gray-50 p-2 border rounded-sm">
                    <h2 className="text-base font-bold">{nota.indice.title === 'Notas' ? 'Nota' : nota.indice.title} del libro {nota.recurso.titulo}</h2>
                    <p className="leading-normal">{nota.text}</p>
                </div>

                {nota?.audio?             
                    <audio controls className="w-full my-2 rounded border border-gray-200 bg-gray-100">
                        <source src={`${process.env.REACT_APP_API}${nota.audio}`} type="audio/mpeg" />
                        Your browser does not support the audio tag.
                    </audio>
                : null}

                <div className='flex space-x-2 flex-wrap mb-10'>
                    {nota?.tags?.map(tag => {
                        return <div key={tag.id} className="bg-gray-50 px-2 border rounded-sm text-gray-400">
                            # {tag.name}
                        </div>
                    })}
                </div>
            </>))}

            {notas?.filter(nota => nota.userId === id && !nota.isRemoved).length < 1? 'Sin notas registradas...' : null}
        </div>
        
    </div>
  )
}

export default UserNotas