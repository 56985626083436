import { NavLink as Link, useLocation } from 'react-router-dom'
import { signOut } from './../actions'
import { connect } from 'react-redux'

function Navigation(props) {

  const { signOut, collapse } = props

  const location = useLocation()

  var svg_home = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg>
  var svg_recursos = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-grid"><rect x="3" y="3" width="7" height="7"/><rect x="14" y="3" width="7" height="7"/><rect x="14" y="14" width="7" height="7"/><rect x="3" y="14" width="7" height="7"/></svg>
  var svg_dir = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/></svg>
  var svg_bell = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"/><path d="M13.73 21a2 2 0 0 1-3.46 0"/></svg>
  var svg_user = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
  var svg_dollar = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
  var svg_question = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-help-circle"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  var svg_email = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><polyline points="22,6 12,13 2,6"/></svg>
  var svg_book = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>
  var svg_list = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-list"><line x1="8" y1="6" x2="21" y2="6"/><line x1="8" y1="12" x2="21" y2="12"/><line x1="8" y1="18" x2="21" y2="18"/><line x1="3" y1="6" x2="3.01" y2="6"/><line x1="3" y1="12" x2="3.01" y2="12"/><line x1="3" y1="18" x2="3.01" y2="18"/></svg>
  var svg_msg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/></svg>
  var svg_msg_approved = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/></svg>
  var svg_msg_film = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-film"><rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"/><line x1="7" y1="2" x2="7" y2="22"/><line x1="17" y1="2" x2="17" y2="22"/><line x1="2" y1="12" x2="22" y2="12"/><line x1="2" y1="7" x2="7" y2="7"/><line x1="2" y1="17" x2="7" y2="17"/><line x1="17" y1="17" x2="22" y2="17"/><line x1="17" y1="7" x2="22" y2="7"/></svg>
  var svg_logout = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/><polyline points="16 17 21 12 16 7"/><line x1="21" y1="12" x2="9" y2="12"/></svg>


  var svg_check = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" strokeWidth="2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="absolute feather feather-check" style={{ marginLeft: '3px', marginBottom: '1px' }}><polyline points="20 6 9 17 4 12"/></svg>

  return (
    <div className={`h-screen border-r w-56 sticky top-0 ${!collapse? 'hidden' : ''}`}>
      <nav className="navigation flex flex-col">

        <Link to="/" exact activeClassName="bg-blue-100" className={'flex items-center'}>
          {svg_home}
          Home
        </Link>

        <Link to="/recursos" className={`flex items-center ${'recursos' === location.pathname.split('/')[1]? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_recursos}
          Recursos
        </Link>

        <Link to="/recursos-de-video" className={`flex items-center ${'recursos-de-video' === location.pathname.split('/')[1]? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_msg_film}
          Recursos en vídeo
        </Link>

        <Link to="/planes" className={`flex items-center ${/planes/.test(location.pathname) || /plancategorias/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_dir}
          Planes de lectura
        </Link>

        <Link to="/sugeridos" className={`flex items-center ${/sugeridos/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_book}
          Sugeridos de búsqueda
        </Link>

        <Link to="/notificaciones" className={`flex items-center ${/notificaciones/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_bell}
          Notificaciones
        </Link>
        
        <Link to="/usuarios" className={`flex items-center ${/usuarios/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_user}
          Usuarios
        </Link>

        <Link to="/suscripciones" className={`flex items-center ${/suscripciones/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_list}
          Suscripciones
        </Link>

        <Link to="/registros" className={`flex items-center ${/registros/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_dollar}
          Registro de suscripciones
        </Link>

        <Link to="/emails" className={`flex items-center ${/emails/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_email}
          Emails
        </Link>

        <Link to="/opiniones" className={`flex items-center ${'opiniones' === location.pathname.split('/')[1]? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_msg}
          Opiniones
        </Link>

        <Link to="/opiniones-aprobadas" className={`flex items-center ${'opiniones-aprobadas' === location.pathname.split('/')[1]? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          <div className='flex items-center'>{svg_check}{svg_msg_approved}</div>
          Testimonios (web)
        </Link>

        <Link to="/soporte" className={`flex items-center ${/soporte/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_question}
          Soporte técnico
        </Link>
        
        <hr></hr>


        <div onClick={signOut} className={`flex items-center px-3 py-2 hover:text-blue-400 cursor-pointer`}>
          {svg_logout}
          Cerrar sesión
        </div>



        <hr></hr>

        {/* <Link to="/emails" className={`flex items-center ${/emails/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_email}
          Emails
        </Link>

        <Link to="/suscripciones" className={`flex items-center ${/suscripciones/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_list}
          Suscripciones
        </Link>

        <Link to="/plancategorias" className={`flex items-center ${/plancategorias/.test(location.pathname)? `bg-blue-100` : ``}`} activeClassName="bg-blue-100">
          {svg_list}
          Categorías de planes
        </Link>

        <hr></hr> */}
      </nav>
    </div>
  )
}

/* export default Navigation */

export default connect(state => ({auth: state.firebase.auth}), dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
})(Navigation)