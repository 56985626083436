import * as types from '../constants/ActionTypes';
import { request, gql } from 'graphql-request';
import axios from "axios";

export const signIn = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then( async (res) => {

      await dispatch(createUser({
        id: res.user.uid,
        provider: "Google",
        email: res.user.email,
        name: res.user.displayName,
        surname: "",
        image: res.user.photoURL,
      }));


      function getBrowser() {
        var sUsrAg = navigator.userAgent;
    
        if (sUsrAg.indexOf("Firefox") > -1) {
          return "Mozilla Firefox";
          // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
          return "Samsung Internet";
          // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
          return "Opera";
          // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
          return "Microsoft Internet Explorer";
          // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
          return "Microsoft Edge";
          // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
          return "Google Chrome or Chromium";
          // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
          return "Apple Safari";
          // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
          return null;
        }
      }
    
      function getOperativeSystem() {
        let operatingSystem = null;
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux'; }
        if (window.navigator.appVersion.indexOf('Android') !== -1) { operatingSystem = 'Android'; }
        return operatingSystem
      }
    
      function getDeviceType() {
        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent) || window.navigator.appVersion.indexOf('Android') !== -1) {
          return "Mobile";
        } else {
          return "Desktop";
        }
      }


      var userInfo = await axios.get('https://geolocation-db.com/json/');

      await dispatch(registerLogIn({
        userId: res.user.uid,
        ip: userInfo.data.IPv4,
        agent: getBrowser(),
        fullAgent: navigator.userAgent,
        operativeSystem: getOperativeSystem(),
        fullOperativeSystem: window.navigator.appVersion,
        deviceType: getDeviceType(),
        site: 'Atril Flamenco Admin Web'
      }));


      await dispatch({ type: types.LOGIN_SUCCESS });
      window.location.href = "/";

    }).catch(error => {
      dispatch({ type: types.LOGIN_ERROR, error });
    });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signOut().then( async () => {
      await dispatch({ type: types.LOGOUT_SUCCESS });
      window.location.href = "/login";
    });
  };
};




export const createUser = (input) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: String!, $name: String, $surname: String, $email: String, $provider: String, $image: String) {
          createUser(input: { id: $id, name: $name, surname: $surname, email: $email, provider: $provider, image: $image }) {
            id
            name
            surname
            email
            provider
          }
        }
      `,
      input
    ).then(response => 
      dispatch({
        type: types.NEW_USER,
        payload: response.createUser,
      })
    );
  };
};


export const registerLogIn = (input) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($userId: String, $ip: String, $agent: String, $fullAgent: String, $operativeSystem: String, $fullOperativeSystem: String, $deviceType: String, $site: String) {
          addLogInLog(
            input: { userId: $userId, ip: $ip, agent: $agent, fullAgent: $fullAgent, operativeSystem: $operativeSystem, fullOperativeSystem: $fullOperativeSystem, deviceType: $deviceType, site: $site }
          ) {
            id
          }
        }
      `,
      {userId: input.userId, ip: input.ip, agent: input.agent, fullAgent: input.fullAgent, operativeSystem: input.operativeSystem, fullOperativeSystem: input.fullOperativeSystem, deviceType: input.deviceType, site: input.site, }
    ).then(response => 
      dispatch({
        type: types.NEW_USER,
        payload: response.addLogInLog,
      })
    );
  };
};


export const createBasicUser = (input) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($email: String!, $rol: String!) {
          createBasicUser(input: { id: $email, email: $email, rol: $rol })
        }
      `,
      input
    ).then(response =>
      dispatch({
        type: types.NEW_USER,
        payload: response.createBasicUser,
      })
    );
  };
};




export const getAllUsers = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          users {
            id
            name
            email
            image
            provider
            actualPrice
            lastLogin
            createdAt
            rol
            suscriberUntilNew
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_USERS,
        payload: response.users,
      })
    );
  };
};

export const getUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          user(id: $id) {
            id
            name
            email
            rol
            image
            language
            suscriberUntilNew
            enabled
            createdAt
            lastLogin
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.GET_USER,
        payload: response.user,
      })
    );
  };
};

export const getDestacadosByUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          destacadosByUser(userId: $userId) {
            id
            userId
            recurso {
              titulo
            }
            indice {
              title
            }
            tags {
              id
              name
              userId
              slug
            }
            text
            isRemoved
          }
        }
      `,
      { userId: id }
    ).then(response =>
      dispatch({
        type: types.GET_DESTACADOS_BY_USER,
        payload: response.destacadosByUser,
      })
    );
  };
};

export const getNotasByUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          notasByUser(userId: $userId) {
            id
            userId
            recursoToken
            audio
            recurso {
              id
              token
              titulo
              slug
            }
            indice {
              id
              title
              depth
              libro
              slug
            }
            userId
            recursoToken
            parent
            indiceId
            esPlan
            planId
            isRemoved
            destacado
            text
            tags {
              id
              name
            }
          }
        }
      `,
      { userId: id }
    ).then(response =>
      dispatch({
        type: types.GET_NOTAS_BY_USER,
        payload: response.notasByUser,
      })
    );
  };
};

export const getTagsByUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          TagsByUser(userId: $userId) {
            id
            name
            userId
            slug
          }
        }
      `,
      { userId: id }
    ).then(response =>
      dispatch({
        type: types.GET_TAGS_BY_USER,
        payload: response.TagsByUser,
      })
    );
  };
};

export const getIndicesDestacadosByUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          indicesDestacadosByUser(userToken: $userId) {
            id
            title
            depth
            slug
            isSaved(userId: $userId)
            isCompleted(userId: $userId)
            resource {
              titulo
              slug
              image
            }
            parent {
              title
              slug
            }
          }
        }
      `,
      { userId: id }
    ).then(response =>
      dispatch({
        type: types.GET_INDICES_DESTACADOS_BY_USER,
        payload: response.indicesDestacadosByUser,
      })
    );
  };
};

export const getIndicesCompleted = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          indicesCompleteds(userToken: $userId) {
            id
            title
            depth
            slug
            isSaved(userId: $userId)
            isCompleted(userId: $userId)
            resource {
              titulo
              slug
              image
            }
            parent {
              title
              slug
            }
          }
        }
      `,
      { userId: id }
    ).then(response =>
      dispatch({
        type: types.GET_INDICES_COMPLETEDS_BY_USER,
        payload: response.indicesCompleteds,
      })
    );
  };
};




export const lastRecentActivity = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          lastRecentActivity {
            id
            title
            message
            type
            slug
            userId
            createdAt
            user {
              id
              name
              email
              __typename
            }
            
          }
        }
      `,
    ).then(response =>
      dispatch({
        type: types.GET_ALL_RECENT_ACTIVITY,
        payload: response.lastRecentActivity,
      })
    );
  };
};



export const getDataBook = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($slug: String!) {
          getRecursoBySlugAdmin(slug: $slug) {
            id
            titulo
            author
            subtitulo
            secondarySubtitle
            descripcion
            es_libro
            language
            token
            slug
            image
            files
            imagesUploaded
            audiosUploaded
            images
            audios
            references
            existReferencesFile
            translation_of
            tableOfContents {
              id
              title
              depth
              slug
              parentId
              position
              fileName
              fileText
              fileAbsolutPath
            }
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_DATA_BOOK,
        payload: response.getRecursoBySlugAdmin,
      })
    );
  };
};


export const getSmallDataBooks = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          getSmallDataBooks {
            id
            token
            titulo
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_BOOKS,
        payload: response.getSmallDataBooks,
      })
    );
  };
};


export const getAllBooks = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          books {
            id
            titulo
            subtitulo
            slug
            es_libro
            language
            token
            createdAt
            tableOfContents {
              id
              title
              depth
              slug
              position
              parentId
              resource {
                id
                token
                titulo
              }
            }
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_BOOKS,
        payload: response.books,
      }),
    );
  };
};


export const getAllVideoResources = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          recursosDeVideo {
            id
            title
            subtitle
            author
            language
            translation_of
            image
            description
            videos
            isPublished
            createdAt
            updatedAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_VIDEO_RESOURCES,
        payload: response.recursosDeVideo,
      })
    );
  };
};


export const getVideoResource = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          recursoDeVideo(id: $id) {
            id
            title
            subtitle
            author
            language
            translation_of
            image
            description
            sections
            subSections
            videos
            isPublished
            createdAt
            updatedAt
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.GET_VIDEO_RESOURCES,
        payload: response.recursoDeVideo,
      })
    );
  };
};




export const getLogLoginsByUser = userId => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($userId: String!) {
          logLogInsByUser(userId: $userId) {
            id
            userId
            ip
            agent
            fullAgent
            operativeSystem
            fullOperativeSystem
            deviceType
            site
            createdAt
            updatedAt
          }
        }
      `,
      { userId: userId }
    ).then(response =>
      dispatch({
        type: types.GET_LOG_LOGINS_BY_USER,
        payload: response.logLogInsByUser,
      })
    );
  };
};





export const getPlanBySlug = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query getPlanBySlug($slug: String!) {
          getPlanBySlug(slug: $slug) {
            id
            titulo
            subtitulo
            fecha
            descripcion
            categoriaId
            duracion
            language
            relationTableOfContents {
              id
              contentId
            }
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_PLAN,
        payload: response.getPlanBySlug,
      })
    );
  };
};





export const getDataAudios = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query getDataAudios($slug: String!) {
          getDataAudios(slug: $slug) {
            id
            refId
            text
            fileName
            filePath
            recurso
            createdAt
            updatedAt
          }
        }
      `,
      { slug: slug }
    ).then(response => {
      return dispatch({
        type: types.GET_AUDIOS_BY_SLUG,
        payload: response.getDataAudios,
      })
      }
    );
  };
};












export const getAllIndices = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          indices {
            id
            title
            libro
            depth
            parentId
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_INDICES,
        payload: response.indices,
      })
    );
  };
};



export const getAllPlans = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          plans {
            id
            titulo
            slug
            language
            categoriaId
            createdAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_PLANS,
        payload: response.plans,
      })
    );
  };
};



export const getAllPlanCategorias = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          planCategorias {
            id
            name
            color
            description
            createdAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_PLAN_CATEGORIAS,
        payload: response.planCategorias,
      })
    );
  };
};



export const getEmailTemplates = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          emailTemplates {
            id
            slug
            title
            body
            createdAt
            updatedAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_EMAIL_TEMPLATES,
        payload: response.emailTemplates,
      })
    );
  };
};


export const emailTemplatesBySlug = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($slug: String!) {
          emailTemplatesBySlug(slug: $slug) {
            id
            slug
            title
            body
            language
            createdAt
            updatedAt
            __typename
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_EMAIL_TEMPLATES,
        payload: response.emailTemplatesBySlug,
      })
    );
  };
};


export const getEmailTemplate = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($slug: String!) {
          emailTemplate(slug: $slug) {
            id
            slug
            title
            body
            createdAt
            updatedAt
            __typename
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_EMAIL_TEMPLATE,
        payload: response.emailTemplate,
      })
    );
  };
};






export const getAllConsultas = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          messages {
            id
            topic
            body
            isReaded
            closed
            createdAt
            userToId
            userFromId
            __typename

            userFrom {
              id
              name
              rol
              image
            }

            userTo {
              id
              name
              rol
              image
            }
      
            responses {
              id
              topic
              body
              isReaded
              closed
              createdAt
              userToId
              userFromId

              userFrom {
                id
                name
                rol
                image
              }

              userTo {
                id
                name
                rol
                image
              }
            }
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_MENSAJES,
        payload: response.messages,
      })
    );
  };
};


export const getConsulta = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query getConsulta($id: ID!) {
          message(id: $id) {
            id
            topic
            body
            isReaded
            closed
            createdAt
            userToId
            userFromId
            __typename

            userFrom {
              id
              name
              email
              rol
              image
              lastLogin
            }

            userTo {
              id
              name
              email
              rol
              image
              lastLogin
            }
      
            responses {
              id
              topic
              body
              isReaded
              closed
              createdAt
              userToId
              userFromId

              userFrom {
                id
                name
                email
                rol
                image
                lastLogin
              }

              userTo {
                id
                name
                email
                rol
                image
                lastLogin
              }
            }
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.GET_MENSAJE,
        payload: response,
      })
    );
  };
};











export const getRecursoBySlug = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query getRecursoBySlug($slug: String!) {
          getRecursoBySlug(slug: $slug) {
            id
            token
            titulo
            subtitulo
            slug
            descripcion
            author
            image
            es_libro
            language
            folderName
            files
            imagesUploaded
            audiosUploaded
            tableOfContents {
              id
              title
              depth
              slug
              position
              parentId
            }
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_RECURSO_BY_SLUG,
        payload: response.getRecursoBySlug,
      })
    );
  };
};


export const getAllNotifications = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          notifications {
            id

            title
            body
            link

            titleENG
            bodyENG
            linkENG

            isPublished
            publishedAt
            createdAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_NOTIFICATIONS,
        payload: response.notifications,
      })
    );
  };
};


export const getAllSuscripciones = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          subscriptions {
            id
            title
            slug
            summary
            description
            price
            daysDuration
            monthsDuration
            enabled
            createdAt
            updatedAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_SUBSCRIPTIONS,
        payload: response.subscriptions,
      })
    );
  };
};

export const getAllGlossaryWords = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          glossaryWords {
            id
            word
            createdAt
            updatedAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_GLOSSARY_WORDS,
        payload: response.glossaryWords,
      })
    );
  };
};


export const getGlossaryWord = (id) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          glossaryWord(id: $id) {
            id
            word
            createdAt
            updatedAt
            __typename
          }
        }
      `,
      {id}
    ).then(response =>
      dispatch({
        type: types.GET_GLOSSARY_WORD,
        payload: response.glossaryWord,
      })
    );
  };
};





export const getAllChapterReviews = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          chapterReviews {
            id
            stars
            body
            indiceId
            userId
            createdAt
            updatedAt
            user {
              id
              name
              email
              rol
              image
              lastLogin
            }
            indice {
              id
              title
              libro
              depth
              parentId
              resource {
                id
                token
                titulo
                subtitulo
                slug
                descripcion
                author
                image
                es_libro
                language
              }
            }
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_CHAPTER_REVIEWS,
        payload: response.chapterReviews,
      })
    );
  };
};


export const getChapterReview = (id) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          chapterReview(id: $id) {
            id
            stars
            body
            indiceId
            userId
            createdAt
            updatedAt
            user {
              id
              name
              email
              rol
              image
              lastLogin
            }
            indice {
              id
              title
              libro
              depth
              parentId
              resource {
                id
                token
                titulo
                subtitulo
                slug
                descripcion
                author
                image
                es_libro
                language
              }
            }
            __typename
          }
        }
      `,
      {id}
    ).then(response =>
      dispatch({
        type: types.GET_CHAPTER_REVIEW,
        payload: response.chapterReview,
      })
    );
  };
};


export const getAllApprovedChapterReviews = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          approvedChapterReviews {
            id
            stars
            body
            bodyENG
            username
            image
            createdAt
            updatedAt
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_APPROVED_CHAPTER_REVIEWS,
        payload: response.approvedChapterReviews,
      })
    );
  };
};

export const getApprovedChapterReview = (id) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          approvedChapterReview(id: $id) {
            id
            stars
            body
            bodyENG
            username
            image
            createdAt
            updatedAt
            __typename
          }
        }
      `,
      {id}
    ).then(response =>
      dispatch({
        type: types.GET_APPROVED_CHAPTER_REVIEW,
        payload: response.approvedChapterReview,
      })
    );
  };
};











export const getUserSuscripcion = (id) => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          userSubscription(id: $id) {
            id
            userToken
            subscriptionId
            price
            endsAt
            createdAt
            updatedAt
            user {
              id
              name
              email
              rol
              image
              lastLogin
            }
            __typename
          }
        }
      `,
      {id}
    ).then(response =>
      dispatch({
        type: types.GET_ALL_SUBSCRIPTIONS,
        payload: response.userSubscription,
      })
    );
  };
};

export const getAllUserSuscripciones = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          userSubscriptions {
            id
            userToken
            subscriptionId
            price
            endsAt
            createdAt
            updatedAt
            user {
              id
              name
              email
              rol
              image
              lastLogin
            }
            __typename
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_SUBSCRIPTIONS,
        payload: response.userSubscriptions,
      })
    );
  };
};



export const getNumberOfSubscriptions = () => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        {
          getNumberOfSubscriptions {
            id
            userToken
            subscriptionId
            endsAt
            createdAt
            updatedAt
            user {
              id
              name
              email
              rol
              image
              lastLogin
            }
          }
        }
      `
    ).then(response =>
      dispatch({
        type: types.GET_ALL_SUBSCRIPTIONS,
        payload: response.getNumberOfSubscriptions,
      })
    );
  };
};








export const getSuscripcionBySlug = slug => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($slug: String!) {
          getSuscripcionBySlug(slug: $slug) {
            id
            slug

            title
            summary
            description

            titleENG
            summaryENG
            descriptionENG

            price
            daysDuration
            monthsDuration
            enabled
            createdAt
            updatedAt
            __typename
          }
        }
      `,
      { slug: slug }
    ).then(response =>
      dispatch({
        type: types.GET_SUBSCRIPTION,
        payload: response.getSuscripcionBySlug,
      })
    );
  };
};










export const getNotification = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          notification(id: $id) {
            id

            title
            body
            link

            titleENG
            bodyENG
            linkENG

            createdAt
            isPublished
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.GET_NOTIFICATION,
        payload: response.notification,
      })
    );
  };
};


export const getPlanCategoria = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($id: ID!) {
          planCategoria(id: $id) {
            id
            name
            color
            description
            createdAt
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.GET_PLAN_CATEGORIA,
        payload: response.planCategoria,
      })
    );
  };
};



export const getMultipleConfigProjectByKey = keys => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        query ($keys: String!) {
          multipleConfigProjectByKey(keys: $keys) {
            id
            position
            isHidden
            name
            key
            value
            createdAt
            updatedAt
          }
        }
      `,
      { keys: keys }
    ).then(response =>
      dispatch({
        type: types.GET_ALL_PROJECT_CONFIGS,
        payload: response.multipleConfigProjectByKey,
      })
    );
  };
};


export const saveConfigProject = input => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($key: String, $name: String, $value: String) {
          updateConfigProjectByTexts(input: { key: $key, name: $name, value: $value }) {
            id
            position
            isHidden
            name
            key
            value
            createdAt
            updatedAt
          }
        }
      `,
      input
    ).then(response =>
      dispatch({
        type: types.UPDATE_PROJECT_CONFIG,
        payload: response.updateConfigProjectByTexts,
      })
    );
  };
};








export const sendTooManyIpsWarning = input => {
  console.log(' ')
  console.log('input')
  console.log(input)

  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($userId: String, $email: String, $name: String, $subSlug: String, $bodyTMP: String, $recursoToken: String, $planToken: String) {
          createLogEmail(input: { userId: $userId, email: $email, name: $name, subSlug: $subSlug, bodyTMP: $bodyTMP, recursoToken: $recursoToken, planToken: $planToken }) {
            id
            userId
            email
            name
            title
            body
            sended
            sendedAt
            createdAt
            updatedAt
          }
        }
      `,
      input
    ).then(response =>
      dispatch({
        type: types.NEW_LOG_EMAIL,
        payload: response.createLogEmail,
      })
    );
  };
};









export const createNotification = input => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($title: String, $body: String, $link: String, $titleENG: String, $bodyENG: String, $linkENG: String) {
          newNotification(input: { title: $title, body: $body, link: $link, titleENG: $titleENG, bodyENG: $bodyENG, linkENG: $linkENG }) {
            id

            title
            body
            link

            titleENG
            bodyENG
            linkENG

            createdAt
            __typename
          }
        }
      `,
      input
    ).then(response =>
      dispatch({
        type: types.NEW_NOTIFICATION,
        payload: response.newNotification,
      })
    );
  };
};







export const createPlanCategoria = input => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($name: String!) {
          newPlanCategoria(input: { name: $name }) {
            name
          }
        }
      `,
      input
    ).then(response =>
      dispatch({
        type: types.NEW_PLAN_CATEGORIA,
        payload: response.newPlanCategoria,
      })
    );
  };
};






export const publishNotification = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          publishNotification(id: $id) {
            id
            isPublished
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.PUBLISH_NOTIFICATION,
        payload: response.publishNotification,
      })
    );
  };
};

export const deleteNotification = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteNotification(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_NOTIFICATION,
        payload: response.deleteNotification,
      })
    );
  };
};

export const deleteApprovedChapterReview = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteApprovedChapterReview(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_CHAPTER_REVIEW,
        payload: response.deleteApprovedChapterReview,
      })
    );
  };
};



export const deleteGlossaryWord = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteGlossaryWord(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_GLOSSARY_WORD,
        payload: response.deleteGlossaryWord,
      })
    );
  };
};


export const markAsReaded = initialId => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation MarkMessagesAsReaded($initialId: Int!) {
          markMessagesAsReadedAdmins(initialId: $initialId) {
            id
            topic
            body
            isReaded
            closed
            createdAt
            userFromId
            userToId
        
            userFrom {
              id
              name
              rol
              image
            }
        
            responses {
              id
              topic
              body
              isReaded
              closed
              createdAt
              userFromId
              userToId
        
              userFrom {
                id
                name
                rol
                image
              }
            }
          }
        }
      `,
      { initialId: initialId }
    ).then(response =>
      dispatch({
        type: types.MARK_MENSAJE,
        payload: response.markMessagesAsReadedAdmins,
      })
    );
  };
};



export const deletePlan = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deletePlan(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_PLAN,
        payload: response.deletePlan,
      }),
    );
  };
};


export const deleteSuscripcion = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteSubscription(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_SUBSCRIPTION,
        payload: response.deleteSubscription,
      }),
    );
  };
};

export const deletePlanCategoria = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deletePlanCategoria(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_PLAN,
        payload: response.deletePlanCategoria,
      }),
    );
  };
};


export const deleteUser = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteUser(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_USER,
        payload: response.deleteUser,
      }),
    );
  };
};

export const deleteRecurso = id => {
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID!) {
          deleteRecurso(id: $id) {
            status
          }
        }
      `,
      { id: id }
    ).then(response =>
      dispatch({
        type: types.DELETE_BOOK,
        payload: response.deleteRecurso,
      }),
    );
  };
};


export const updateNotification = input => {
  const { id, title, body, link, titleENG, bodyENG, linkENG } = input;
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID, $title: String, $body: String, $link: String, $titleENG: String, $bodyENG: String, $linkENG: String) {
          updateNotification(input: { id: $id, title: $title, body: $body, link: $link, titleENG: $titleENG, bodyENG: $bodyENG, linkENG: $linkENG }) {
            id

            title
            body
            link

            titleENG
            bodyENG
            linkENG
          }
        }
      `,
      { id: id, title: title, body: body, link: link, titleENG: titleENG, bodyENG: bodyENG, linkENG: linkENG }
    ).then(response =>
      dispatch({
        type: types.UPDATE_NOTIFICATION,
        payload: response.updateNotification,
      })
    );
  };
};




export const createMensaje = input => {
  const { responseId, topic, body, isReaded, closed, userFromId, userToId } = input;

  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ( 
          $responseId: Int, 
          $topic: String, 
          $body: String, 
          $isReaded: Boolean, 
          $closed: Boolean, 
          $userFromId: String, 
          $userToId: String 
        ) {
          createMensaje( input: { 
            responseId: $responseId, 
            topic: $topic,
            body: $body, 
            isReaded: $isReaded, 
            closed: $closed, 
            userFromId: $userFromId, 
            userToId: $userToId 
          }) {
      
            id
            topic
            body
            isReaded
            closed
            createdAt
            userToId
            userFromId

            userFrom {
              id
              name
              rol
              image
            }

            userTo {
              id
              name
              rol
              image
            }
      
            responses {
              id
              topic
              body
              isReaded
              closed
              createdAt
      
              userFrom {
                id
                name
                rol
                image
              }
            }
      
              
          }
        }
      `,
      { 
        responseId: responseId, 
        topic: topic,
        body: body, 
        isReaded: isReaded, 
        closed: closed, 
        userFromId: userFromId, 
        userToId: userToId  
      }
    ).then(response =>
      dispatch({
        type: types.NEW_MENSAJE,
        payload: response,
      })
    );
  };
};



export const updatePlanCategoria = input => {
  const { id, name, color, description } = input;
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: ID, $name: String, $description: String, $color: String) {
          updatePlanCategoria(input: { id: $id, name: $name, description: $description, color: $color }) {
            id
            name
            color
            description
          }
        }
      `,
      { id: id, name: name, description: description, color: color }
    ).then(response =>
      dispatch({
        type: types.UPDATE_PLAN_CATEGORIA,
        payload: response.updatePlanCategoria,
      })
    );
  };
};






export const updateEmailTemplate = input => {
  const { id, title, body } = input;
  return dispatch => {
    request(
      `${process.env.REACT_APP_API}graphql`,
      gql`
        mutation ($id: String, $title: String, $body: String) {
          updateEmailTemplate(input: { id: $id, title: $title, body: $body }) {
            id
            title
            body
          }
        }
      `,
      { id: id, title: title, body: body }
    ).then(response =>
      dispatch({
        type: types.UPDATE_EMAIL_TEMPLATE,
        payload: response.updateEmailTemplate,
      })
    );
  };
};


