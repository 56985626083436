import * as types from '../constants/ActionTypes'

const logEmails = (state = [], action) => {
  switch(action.type) {
    case types.GET_LOG_EMAILS:
      return action.payload
    case types.NEW_LOG_EMAIL:
      return action.payload
    default:
      return state
  }
}

export default logEmails