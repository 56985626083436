import * as types from '../constants/ActionTypes'

const configProjects = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_PROJECT_CONFIGS:
      return action.payload
    case types.GET_PROJECT_CONFIG:
      return action.payload
    case types.UPDATE_PROJECT_CONFIG:
      return action.payload
    default:
      return state
  }
}

export default configProjects