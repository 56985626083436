import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import authentication from './authentication';
import users from './users';
import { book, books } from './books';
import { indices } from './indices';

import plans from './plans';
import audios from './audios';
import planCategorias from './planCategorias';
import configProjects from './configProjects';
import logEmails from './logEmails';
import videoResources from './videoResources';
import notifications from './notifications';
import mensajes from './mensajes';
import suscripciones from './suscripciones';
import glossaryWords from './glossaryWords';
import chapterReviews from './chapterReviews';
import approvedChapterReviews from './approvedChapterReviews';
import recentActivity from  './recentActivity';
import emailTemplates from './emailTemplates';
import logLogIn from './logLogIn';
import destacados from './destacados';
import notas from './notas';
import tags from './tags';
import indicesDestacados from './indicesDestacados';
import indicesCompleted from './indicesCompleted';


const rootReducer = combineReducers({
  auth: authentication,
  book: book,
  books: books,
  videoResources: videoResources,
  indices: indices,
  users: users,
  logLogIn: logLogIn,
  plans: plans,
  audios: audios,
  planCategorias: planCategorias,
  configProjects: configProjects,
  logEmails: logEmails,
  emailTemplates: emailTemplates,
  indicesCompleted: indicesCompleted,
  indicesDestacados: indicesDestacados,
  suscripciones: suscripciones,
  glossaryWords: glossaryWords,
  chapterReviews: chapterReviews,
  approvedChapterReviews: approvedChapterReviews,
  /* suscripcionesUser: suscripcionesUser, */
  notifications: notifications,
  destacados: destacados,
  notas: notas,
  tags: tags,
  mensajes: mensajes,
  recentActivity: recentActivity,
  firebase: firebaseReducer,
});

export default rootReducer;
