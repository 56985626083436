import React from 'react'

const EmailTemplateFinal = () => {
  // let css = `
  //   .bg-fire-default {
  //     background-color: rgba(179, 48, 0, 1);
  //   }

  //   .bg-fire-default:hover {
  //     background-color: rgba(230, 61, 0, 0.75);
  //   }
  // `

  var template = (
    <html lang="es">
      <head>
        <meta charSet="utf-8" />
      </head>
      <body
        style={{
          fontFamily: `'Open Sans', Helvetica, Arial, sans-serif`,
          backgroundColor: 'rgba(255, 255, 255, 1)'
        }}
      >
        <div style={{ padding: '8px' }}>
          <div style={{ display: 'flex', alignitems: 'center' }}>
            <div
              style={{
                display: 'flex',
                height: '40px !important',
                width: '40px !important',
                borderRadius: '2px',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <a
                style={{
                  display: 'flex',
                  borderRadius: '2px',
                  textDecoration: 'none',
                  position: 'relative',
                  width: '40px',
                  backgroundColor: 'rgba(179, 48, 0, 1)',
                  justifyContent: 'flex-start',
                  paddingLeft: '12px',
                  color: 'rgba(255, 255, 255, 1)',
                  fontWeight: '700',
                  margin: '0px !important',
                  paddingTop: '11px'
                }}
                href={process.env.REACT_APP_ROUTE}
              >
                <p style={{ margin: '0px' }}>A</p>
                <i
                  style={{
                    fontFamily: `ui-serif, Georgia, Cambria, "Times New Roman", Times, serif`,
                    fontWeight: '300',
                    color: 'white'
                  }}
                >
                  f
                </i>
              </a>
            </div>

            <div
              style={{
                borderRadius: '2px',
                height: '40px !important',
                overflow: 'hidden',
                marginLeft: '8px',
                width: '100%'
              }}
            >
              <a
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  position: 'relative',
                  backgroundColor: 'rgba(179, 48, 0, 1)',
                  justifyContent: 'flex-start',
                  color: 'rgba(255, 255, 255, 1)',
                  fontWeight: '700',
                  margin: '0px !important',
                  whiteSpace: 'nowrap',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  paddingTop: '11px',
                  height: '40px !important'
                }}
                href={process.env.REACT_APP_ROUTE}
              >
                <p style={{ margin: '0px' }}>Atril Flamenco</p>
              </a>
            </div>
          </div>

          <div
            style={{
              borderRadius: '2px',
              overflow: 'hidden',
              marginTop: '8px'
            }}
          >
            <div
              style={{
                padding: '12px',
                backgroundColor: 'rgba(243, 244, 246, 0.75)'
              }}
            >
              <p
                style={{
                  fontWeight: '700',
                  margin: '0px !important',
                  fontSize: '18px',
                  lineHeight: '28px',
                  marginTop: '6px'
                }}
              >
                %title%
              </p>
              <hr
                style={{
                  borderTop: 'rgba(156, 163, 175, 0.4) 1px solid',
                  backgroundColor: 'rgba(243, 244, 246, 0.75)',
                  margin: '0px'
                }}
              ></hr>
              <div style={{ marginTop: '8px' }}>%body%</div>
            </div>
          </div>

          <div
            style={{ display: 'flex', alignitems: 'center', marginTop: '8px' }}
          >
            <div
              style={{
                borderRadius: '2px',
                height: '40px !important',
                overflow: 'hidden',
                width: '100%',
                marginRight: '8px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  justifyContent: 'flex-start',
                  fontWeight: '700',
                  margin: '0px !important',
                  whiteSpace: 'nowrap',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  backgroundColor: 'rgba(243, 244, 246, 0.75)',
                  paddingTop: '11px',
                  height: '40px !important'
                }}
              >
                <p style={{ margin: '0px' }}> Atril Flamenco</p>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                height: '40px !important',
                width: '40px !important',
                borderRadius: '2px',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <a
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  borderRadius: '2px',
                  textDecoration: 'none',
                  position: 'relative',
                  width: '40px',
                  backgroundColor: 'rgba(179, 48, 0, 1)',
                  paddingLeft: '12px',
                  color: 'rgba(255, 255, 255, 1)',
                  fontWeight: '700',
                  margin: '0px !important',
                  paddingTop: '11px'
                }}
                href={process.env.REACT_APP_ROUTE}
              >
                <p style={{ margin: '0px' }}>A</p>
                <i
                  style={{
                    fontFamily: `ui-serif, Georgia, Cambria, "Times New Roman", Times, serif`,
                    fontWeight: '300',
                    color: 'white'
                  }}
                >
                  f
                </i>
              </a>
            </div>
          </div>
        </div>
      </body>
    </html>
  )

  return template
}

export default EmailTemplateFinal
