import { useLocation } from 'react-router-dom'
import { NavLink as Link } from 'react-router-dom'
import UserActions from './UserActions'


function renderSwitch(pathname) {
  switch(pathname) {
    case 'registros':
      return 'Registro de suscripciones'
    case 'recursos-de-video':
      return 'Recursos de vídeo'
    case 'planes':
      return 'Planes de lectura'
    case 'soporte':
      return 'Soporte técnico'
    case 'plancategorias':
      return 'Categorías de planes'
    case 'sugeridos':
      return 'Sugeridos de búsqueda'
    case 'opiniones-aprobadas':
      return 'Testimonios (web)'
    default:
      return pathname
  }
}

function Header({setNavIsCollapsed, navIsCollapsed}){
  const { pathname } = useLocation()
  return (
    <header className="flex justify-between items-center border-b px-4 py-5 sticky top-0 bg-white z-10">
      <div className="flex items-center space-x-8">
        <button className="p-2 hover:bg-gray-100 rounded-full" onClick={() => {
          setNavIsCollapsed(!navIsCollapsed)
          localStorage.setItem('navIsCollapsed', !navIsCollapsed)
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        <Link to="/" className="hover:no-underline">
          <div className={`w-10 h-10 text-xl bg-fire-default hover:bg-fire-400 transition rounded flex justify-center items-center text-white font-bold transform`}>A<i className="font-serif font-light">f</i></div>
        </Link>

        {pathname?.split('/').length >= 3 &&
          <Link to={`/${pathname?.split('/')[1]}`} className="p-2 hover:bg-gray-100 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </Link>
        }
        
        <h1 className="text-2xl font-semibold"> {(renderSwitch(pathname?.split('/')[1])).charAt(0).toUpperCase() + (renderSwitch(pathname?.split('/')[1])).slice(1)} </h1>
      </div>
      {/* <div className="flex items-center space-x-8">
        <UserActions />
      </div> */}
    </header>
  )
}

export default Header