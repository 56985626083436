import React, { useState, useEffect } from 'react';
import { Form, Button, List, Row, Col, Progress } from 'antd';
import { UploadOutlined, InboxOutlined, CheckCircleOutlined, ExclamationCircleOutlined,} from '@ant-design/icons';
import axios from 'axios';
  const svg_empty_image = process.env.REACT_APP_API+'default.png';

function StepFinal(props) {

  const { 
    submitResource, 
    audios, 
    images, 
    backStep, 
    filesUpload, 
    indices,

    isLoadingIndices,
    isLoadingIndiceFiles,
    isLoadingAudioFiles,
    isLoadingImageFiles,
    isLoadingEnding,

    indicesBig,
    totalWithoutUploadImages,
    totalUploadedImages,
    totalToUploadedImages,

    totalWithoutUploadAudios,
    totalUploadedAudios,
    totalToUploadedAudios,

    book,
    slug,
    textTest,
    titleText,
    subtitleText,
    secondarySubtitleText,
    authorText,
    isBookText,
  } = props;

  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_empty_image = process.env.REACT_APP_API+'default.png';




  return (
    <div className=''>

      {isLoadingIndices?
        <div className="w-full flex justify-center flex-wrap mb-4 bg-gray-200 p-5">
          {isLoadingEnding? <p className="w-full">Estamos acabando...</p> :
            isLoadingImageFiles? <p className="w-full">Guardando imágenes...</p> :
            isLoadingAudioFiles? <p className="w-full">Guardando audios...</p> :
            isLoadingIndiceFiles? <p className="w-full">Guardando markdowns...</p> :
            isLoadingIndices? <p className="w-full">Procesando los indices...</p> : null
          }

          <Progress percent={
            isLoadingEnding? 99 :
            isLoadingImageFiles? 80 :
            isLoadingAudioFiles? 60 :
            isLoadingIndiceFiles? 40 :
            isLoadingIndices? 20 : 0
          } status="active"/>
        </div> 
      : null }






      <Form onFinish={submitResource} className="flex flex-wrap">

        <div className="mb-5 flex w-full flex-wrap">


            <div className='mb-5 flex w-full'>


              <div className='mr-5'>
                <div  style={{ width: '350px', minWidth: '350px', height: '466px', minHeight: '466px' }}>
                  <img id="imgshowfinal" className={'object-cover border rounded-sm'} style={{ width: '350px', minWidth: '350px', height: '466px', minHeight: '466px' }} src={slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image} alt={`Portada ${book.titulo}`} />
                </div>
              </div>

              <div className='w-full overflow-y-auto overflow-x-visible'>

                <div className='flex flex-wrap w-full'>
                  <div className='my-3 w-full'>
                    <h3 className='text-4xl font-bold'>{titleText}</h3>
                    <h3 className="initial text-xl mx-auto" dangerouslySetInnerHTML={{ __html: subtitleText }}></h3>
                    <h3 className="initial text-xl mx-auto" dangerouslySetInnerHTML={{ __html: secondarySubtitleText }}></h3>
                    <h3 className='text-2xl'>{ document.getElementById('subtitle')? document.getElementById('subtitle').value : '' }</h3>
                    <h3 className='text-lg'>{!isBookText? 'Artículo' : isBookText === 1? 'Libro' : 'Curso'} escrito por {document.getElementById('autor')? document.getElementById('autor').value : ''}</h3>
                  </div>
                  <div className='h-40 mt-1 p-2 bg-gray-50 w-full rounded-sm border overflow-auto'>
                    <div className='w-full' dangerouslySetInnerHTML={{__html: textTest}}></div>
                  </div>
                </div>

                <div className='flex flex-wrap justify-end'>
                  <div className='w-full my-3 pl-1'>

                    <div className='flex mb-2 align-middle'>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <ExclamationCircleOutlined className="text-red-600 mr-1 mt-1" />
                        <p>{totalWithoutUploadImages} Imagenes pendientes</p>
                      </div>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <CheckCircleOutlined className="text-blue-400 mr-1 mt-1" />
                        <p>{totalUploadedImages} Imagenes guardadas</p>
                      </div>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <CheckCircleOutlined className="text-green-400 mr-1 mt-1" />
                        <p>{totalToUploadedImages} Imagenes para subir</p>
                      </div>
                    </div>

                    <div className='flex mb-2 align-middle'>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <ExclamationCircleOutlined className="text-red-600 mr-1 mt-1" />
                        <p>{totalWithoutUploadAudios} Audios pendientes</p>
                      </div>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <CheckCircleOutlined className="text-blue-400 mr-1 mt-1" />
                        <p>{totalUploadedAudios} Audios guardados</p>
                      </div>
                      <div className='mr-4 flex align-middle justify-start w-full'>
                        <CheckCircleOutlined className="text-green-400 mr-1 mt-1" />
                        <p>{totalToUploadedAudios} Audios para subir</p>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>


            <Col className='w-full h-60 overflow-y-auto overflow-x-visible border-t border-r border-b border-l'>
              <div className="">
                <div className="ant-list-header pl-6"><div>Indice Generado </div></div>
                {indicesBig && indicesBig.length > 0? 
                  indicesBig.map((item, index) => (
                    item.depth <= 3 || item.parentId === null || item.parent === null?
                      <div key={item.id} className='pl-5 border-t '>

                          <div  className='pb-2 '>

                            <div className='w-full flex flex-wrap'>
                              <div className='w-full pt-2'>
                                {/* <span className="text-xs">{`n${item.position + 1}`}</span> */}
                                <span className="text-xs mx-2">-</span>
                                <span
                                  className='text-left w-full mr-2'
                                  style={{
                                    fontWeight: item.depth > 3 ? `400` : `500`,
                                    fontSize: item.depth > 3 ? `90%` : `100%`,
                                    paddingLeft:
                                      item.depth === 4
                                        ? '1em'
                                        : item.depth === 5
                                        ? '2em'
                                        : 0,
                                  }}
                                >
                                  {item.title === 'Notas' ? `${item.title} (${item.parent})` : item.title.replaceAll('*', '')}
                                </span>
                              </div>




                              {indices.map((itemSecondary, indexSecondary) => (
                                (itemSecondary.parent && itemSecondary.parent+'' === item.title+'') || (itemSecondary.parentId && itemSecondary.parentId+'' === item.id+'') ?
                                  <div key={itemSecondary.slug} className='w-full'>
                                    <span className="text-xs mx-2">·</span>
                                    <span
                                      className='text-left w-full mr-2'
                                      style={{
                                        fontWeight: itemSecondary.depth > 3 ? `400` : `500`,
                                        fontSize: itemSecondary.depth > 3 ? `90%` : `100%`,
                                        paddingLeft:
                                          itemSecondary.depth === 4
                                            ? '1em'
                                            : itemSecondary.depth === 5
                                            ? '2em'
                                            : 0,
                                      }}
                                    >
                                      {itemSecondary.title === 'Notas' ? `${itemSecondary.title} (${itemSecondary.parent})` : itemSecondary.title.replaceAll('*', '')}
                                    </span>



                                  {indices.map((itemTerciary, indexTerciary) => (
                                    (itemTerciary.parent && itemTerciary.parent+'' === itemSecondary.title+'') || (itemTerciary.parentId && itemTerciary.parentId+'' === itemSecondary.id+'') ?
                                      <div key={itemTerciary.slug} className='w-full'>
                                        <span className="text-xs mx-2">·</span>
                                        <span
                                          className='text-left w-full mr-2'
                                          style={{
                                            fontWeight: itemTerciary.depth > 3 ? `400` : `500`,
                                            fontSize: itemTerciary.depth > 3 ? `90%` : `100%`,
                                            paddingLeft:
                                            itemTerciary.depth === 4
                                                ? '1em'
                                                : itemTerciary.depth === 5
                                                ? '2em'
                                                : 0,
                                          }}
                                        >
                                          {itemTerciary.title === 'Notas' ? `${itemTerciary.title} (${itemTerciary.parent})` : itemTerciary.title.replaceAll('*', '')}
                                        </span>
                                      </div>

                                    : null
                                  ))}
                                  
                                </div>
                                : null
                                ))}
                            </div>

                          </div>
                      </div> : null
                  ))
                : <div className='p-4 border-t'>Sin indices generados</div>}

              </div>
            </Col>

        </div>

        <div className='flex w-full justify-between' style={{margin: '0px'}}>
          <Form.Item>
            <Button htmlType="button" size="large" type="primary" onClick={backStep}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_anterior}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" size="large" type="primary" disabled={isLoadingIndices}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                <div className='flex items-center ml-2'>{svg_save}</div>
              </div>
            </Button>
          </Form.Item>
        </div>

      </Form>
    </div>
  );
}

export default StepFinal;
