/* import escapeHtml from 'escape-html'
import { Text } from 'slate' */


export const typePath = type => {

  switch (type) {
    case 'User':
      return 'usuarios';
    case 'Recurso':
      return 'recursos';
    case 'Plan':
      return 'planes';
    case 'Notification':
      return 'notificaciones';
    case 'PlanCategoria':
      return 'plancategorias';
    case 'ConfigProjects':
      return 'configprojects';
    case 'Mensaje':
      return 'soporte';
    case 'Subscription':
      return 'suscripciones';
    case 'GlossaryWord':
      return 'sugeridos';
    case 'ChapterReview':
      return 'opiniones';
    case 'ApprovedChapterReview':
      return 'opiniones-aprobadas';
    case 'RecursoDeVideo':
      return 'recursos-de-video';
    case 'EmailTemplate':
      return 'emails';
    case  "UserSubscriptions":
      return 'registros';
    default:
      return undefined;
  }
};

/* 
export function serialize(node) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<i>${string}</i>`
    }
    if (node.underline) {
      string = `<u>${string}</u>`
    }
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'heading-one':
      return `<h1 class="text-green-500">${children}</h1>`
    case 'heading-two':
      return `<h2 class="text-red-500">${children}</h2>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    default:
      return children
  }
} */


export const sortIndices = async indices => {
  const response = [];
  let tmpArr = [];
  let index = 0;
  for await (let indice of indices) {
    if (indice.depth === 3) {
      if (index !== 0) {
        response.push(tmpArr);
      }
      tmpArr = [];
    }

    tmpArr.push(indice);

    if (index === indices.length - 1) {
      response.push(tmpArr);
    }
    index++;
    // if (indice.depth === 3) {
    //   const parentTitle = indice.title;
    //   const chapter = indices.filter(indice => indice.parent === parentTitle);
    //   chapter.splice(0, 0, indice);
    //   response.push(chapter);
    // } else {

    // }
  }
  return response;
};
