import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllVideoResources } from '../actions'
import { Alert } from 'antd'

function RecursosDeVideo(props) {
  const { getAllVideoResources, videoResources } = props
  const [filter, setFilter] = useState(0)

  useEffect(() => {
    getAllVideoResources()
  }, [])

  
  function close() {
    localStorage.removeItem('deletedVideoResource')
  }


  const actions = [
    {
      label: 'Nuevo recurso de vídeo',
      to: '/recursos-de-video/nuevo',
      type: 'link'
    }
  ]

  return (
    <>
      <Filters setFilter={setFilter} actions={actions} />
      <div className="container">


      {localStorage.getItem('deletedVideoResource')? <div className="my-4">
        <Alert
          showIcon 
          message="Recurso eliminado correctamente."
          type="success"
          onClose={close}
          closable
        />
      </div> : ''}


      <DocumentList 
          data={videoResources}
          filter={filter}
          fields={[
            {
              label: 'Título',
              name: 'title'
            },
            {
              label: 'Subtítulo',
              name: 'subtitle'
            },
            {
              label: 'Creado el',
              name: 'createdAt'
            },
          ]} />
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllVideoResources: () => dispatch(getAllVideoResources()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecursosDeVideo)