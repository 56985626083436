import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllPlans, getAllPlanCategorias, getMultipleConfigProjectByKey, saveConfigProject } from '../actions'


import { Modal, Button, Form, Input, Alert, Steps } from 'antd'


const { TextArea } = Input
const { Step } = Steps;



function Planes(props) {
  const { getAllPlans, plans, getAllPlanCategorias, planCategorias, configProjects, getMultipleConfigProjectByKey, saveConfigProject } = props
  const [filter, setFilter] = useState(0)
  const [optionsTMP, setOptionsTMP] = useState([])
  const [idOptionsTMP, setIdOptionsTMP] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [input, setInput] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)



  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>
  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;





  useEffect(() => {
    getAllPlans()
    getAllPlanCategorias()
    getMultipleConfigProjectByKey('plan_texts_esp,plan_texts_eng')
  }, [])

  useEffect(() => {
    setOptionsTMP(['Todos']);
    setIdOptionsTMP([[0, 'Todos']])
    planCategorias.map(element => {
      setOptionsTMP(optionsTMP => [...optionsTMP, element.name]);
      setIdOptionsTMP(idOptionsTMP => [...idOptionsTMP, [element.id, element.name]]);
    });
  }, [planCategorias])



  const saveNewPlanTexts = async () => {
    saveConfigProject({ key: 'plan_texts_esp', name: input?.title, value: input?.body })
    saveConfigProject({ key: 'plan_texts_eng', name: input?.titleENG, value: input?.bodyENG })
    setIsModalVisible(false)
    setTimeout(function(){ window.location.reload(); }, 300);
  }


  const onChange = event => {
    setInput({ ...input, [event.target.id]: event.target.value })
  }


  function close() {
    localStorage.removeItem('deletedPlan')
  }

  
  const actions = [
    {
      label: 'Editar textos',
      onClick: () => setIsModalVisible(true),
      type: 'button'
    },
    {
      label: 'Categorias',
      to: '/plancategorias',
      type: 'link'
    },
    {
      label: 'Nuevo plan',
      to: '/planes/nuevo',
      type: 'link'
    }
  ]

  return (
    <>
      <Filters options={optionsTMP} setFilter={setFilter} actions={actions} />
      <div className="container">

        {localStorage.getItem('deletedPlan')? <div className="my-4">
          <Alert
            showIcon 
            message="Plan de lectura eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList 
          data={plans}
          other={idOptionsTMP}
          filter={filter}
          fields={[
            {
              label: 'Titulo',
              name: 'titulo'
            },
            {
              label: 'Categoría',
              name: 'categoriaId'
            },
            {
              label: 'Idioma',
              name: 'language'
            },
            {
              label: 'Creada el',
              name: 'createdAt'
            },
            
          ]} />
      </div>








      <Modal
        title="Edición de textos para los planes"
        visible={isModalVisible}
        onOk={saveNewPlanTexts}
        okText="Guardar textos"
        cancelText="Cancelar"

        /* okButtonProps={{ disabled: currentStep === 1? false : true }} */

        onCancel={() => { setIsModalVisible(false); setCurrentStep(0) }}
        destroyOnClose
        >
        <Form
          preserve={false}
          onChange={onChange}
        >
          <div className='px-2 pb-7'>
            <Steps current={currentStep} onChange={setCurrentStep}>
              <Step title="Español" />
              <Step title="Inglés" />
            </Steps>
          </div>


          <div className='mb-3' style={{ display: currentStep === 0? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="title" placeholder="Título en español..." defaultValue={configProjects && configProjects[0]? configProjects[0].name : null}/>
            </Form.Item>
            <Form.Item>
              <TextArea
                id="body"
                defaultValue={configProjects && configProjects[0]? configProjects[0].value : null}
                placeholder="Cuerpo en español..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>

          <div className='mb-3' style={{ display: currentStep === 1? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="titleENG" placeholder="Título en inglés..." defaultValue={configProjects && configProjects[1]? configProjects[1].name : null}/>
            </Form.Item>
            <Form.Item>
              <TextArea
                id="bodyENG"
                defaultValue={configProjects && configProjects[1]? configProjects[1].value : null}
                placeholder="Cuerpo en inglés..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>


        </Form>
      </Modal>









    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    /* getAllBooks: () => dispatch(getAllBooks()), */
    getAllPlans: () => dispatch(getAllPlans()),
    getAllPlanCategorias: () => dispatch(getAllPlanCategorias()),
    getMultipleConfigProjectByKey: (keys) => dispatch(getMultipleConfigProjectByKey(keys)),
    saveConfigProject: (input) => dispatch(saveConfigProject(input)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planes)